<template>
  <div class="content-container local-prices">
    <a-form
      :label-col="{ sm: { span: 12 } }"
      :wrapper-col="{ sm: { span: 12 } }"
      class="localPriceSettingsTable"
    >
      <a-row :gutter="48">
        <a-col
          v-for="field in fields"
          :key="field.id"
          class="form-item"
          :span="12"
        >
          <a-form-item :wrapper-col="{}" :label="field.name">
            <template v-if="field.type !== 'select'">
              <a-input
                @input="
                  e =>
                    setField({
                      index: Number($route.params.id),
                      name: field.dataIndex,
                      value: e.target.value,
                    })
                "
                :placeholder="field.placeholder"
                :value="checkFields(field.dataIndex)"
              />
            </template>
            <template v-else>
              <a-select
                @change="
                  e =>
                    setField({
                      index: Number($route.params.id),
                      name: field.dataIndex,
                      value: e,
                    })
                "
                :defaultValue="checkFields(field.dataIndex)"
              >
                <a-select-option value="active">Активный</a-select-option>
                <a-select-option value="inactive">Не активный</a-select-option>
              </a-select>
            </template>
          </a-form-item>
        </a-col>
      </a-row>
      <A-divider />
      <a-row guuter="48">
        <a-col key="12" class="form-item" :span="12">
          <a-form-item
            :wrapper-col="{}"
            class="local-prices__checkbox local-prices__additional-item"
          >
            <a-checkbox>Виден для других производителей</a-checkbox>
          </a-form-item>
        </a-col>
        <a-col key="13" class="form-item" :span="12">
          <a-form-item
            :wrapper-col="{}"
            :label-col="{}"
            label="Наценка для других производителей"
            class="local-prices__additional-item"
          >
            <a-input placeholder="Введите Количество общих артикулов" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div class="priceSettingsButtonsholder">
      <div class="flex">
        <a-button
          @click="updatePrices($route.params.id)"
          type="primary"
          class="pricesSettings-button"
        >
          Сохранить
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
export default {
  name: "LocalPricesSettings",
  computed: {
    ...mapGetters({
      prices: "prices/prices",
    }),
  },
  async mounted() {
    await this.getPrices({ type: "local" })
    const findItem = this.prices.filter(
      el => el.id === Number(this.$route.params.id)
    )
    this.pricesSingle = { ...findItem[0] }
  },
  data: () => ({
    pricesSingle: {},
    fields: [
      // {
      //   id: 1,
      //   name: "Код",
      //   placeholder: "Введите код прайс-листа",
      //   dataIndex: "code",
      //   value: "",
      // },
      // {
      //   id: 2,
      //   name: "Срок, дн.",
      //   placeholder: "Введите срок",
      //   dataIndex: "deliveryPeriod",
      //   value: "",
      // },
      {
        id: 3,
        name: "Наименование",
        placeholder: "Введите наименование прайс-листа",
        dataIndex: "name",
        value: "",
      },
      // {
      //   id: 4,
      //   name: "Количество загруженных линий",
      //   placeholder: "Введите количество загруженных линий",
      //   dataIndex: "name",

      // },
      // {
      //   id: 5,
      //   name: "Общее количество линий",
      //   placeholder: "Введите общее количество линий",
      //   dataIndex: "name",
      // },
      {
        id: 4,
        name: "Метод загрузки",
        placeholder: "Введите метод загрузки",
        dataIndex: "priceType",
        value: "",
      },
      {
        id: 5,
        name: "Дата загрузки",
        placeholder: "Введите дату загрузки",
        dataIndex: "createdAt",
        value: "",
      },
      // {
      //   id: 6,
      //   name: "Количество локальных артикулов",
      //   placeholder: "Количество локальных артикулов",
      //   dataIndex: "name",
      // },
      // {
      //   id: 7,
      //   name: "Процент загрузки",
      //   placeholder: "Введите Процент загрузки",
      //   dataIndex: "name",
      // },
      // {
      //   id: 6,
      //   name: "Статус прайс-листа",
      //   placeholder: "Введите Статус прайс-листа",
      //   dataIndex: "status",
      //   value: "",
      //   type: "select",
      // },
      // {
      //   id: 10,
      //   name: "Количество общих артикулов",
      //   placeholder: "Введите Количество общих артикулов",
      //   dataIndex: "name",
      // }
    ],
  }),
  methods: {
    ...mapActions({
      getPrices: "prices/fetchPrices",
      updatePrices: "prices/updatePrices",
    }),
    ...mapMutations({
      setPrices: "prices/SET_PRICES",
      setField: "prices/SET_CREATE_FORM_PRICES",
    }),
    handleChange(info) {
      const status = info.file.status
      if (status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (status === "done") {
        this.$message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    downloadbtnClick() {
      console.log("Кнопка нажата")
    },
    checkFields(e) {
      // TODO: Переделать
      if (e === "code") {
        return this.pricesSingle.code
      } else if (e === "deliveryPeriod") {
        return this.pricesSingle.deliveryPeriod
      } else if (e === "name") {
        return this.pricesSingle.name
      } else if (e === "priceType") {
        return this.pricesSingle.priceType
      } else if (e === "createdAt") {
        return this.pricesSingle.createdAt
      } else if (e === "status") {
        return this.pricesSingle.status.toString()
      }
    },
  },
}
</script>

<style scoped>
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pricesSettings-button {
  margin: 0 5px;
}
.selects {
  width: 90px;
}
.priceSettingsButtonsholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 auto;
  align-items: flex-start;
}
.localPriceSettingsTable {
  min-height: 570px;
}
</style>
<style lang="scss">
.local-prices {
  .ant-form-item {
    display: flex;
    flex-direction: column;
  }

  &__checkbox {
    label {
      line-height: 1.2;
      display: flex;
      align-items: center;

      > span {
        font-size: 14px;
      }
    }
  }

  label {
    margin: 0;
    padding: 0;
    margin-left: -15px;
    font-size: 12px !important;
    font-weight: 500;
  }

  &__additional-item {
    margin-left: 24px !important;
  }
}
</style>
