import { render, staticRenderFns } from "./LocalPricesSettings.vue?vue&type=template&id=eb12bb94&scoped=true&"
import script from "./LocalPricesSettings.vue?vue&type=script&lang=js&"
export * from "./LocalPricesSettings.vue?vue&type=script&lang=js&"
import style0 from "./LocalPricesSettings.vue?vue&type=style&index=0&id=eb12bb94&scoped=true&lang=css&"
import style1 from "./LocalPricesSettings.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb12bb94",
  null
  
)

export default component.exports