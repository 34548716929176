var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-container local-prices"},[_c('a-form',{staticClass:"localPriceSettingsTable",attrs:{"label-col":{ sm: { span: 12 } },"wrapper-col":{ sm: { span: 12 } }}},[_c('a-row',{attrs:{"gutter":48}},_vm._l((_vm.fields),function(field){return _c('a-col',{key:field.id,staticClass:"form-item",attrs:{"span":12}},[_c('a-form-item',{attrs:{"wrapper-col":{},"label":field.name}},[(field.type !== 'select')?[_c('a-input',{attrs:{"placeholder":field.placeholder,"value":_vm.checkFields(field.dataIndex)},on:{"input":function (e) { return _vm.setField({
                    index: Number(_vm.$route.params.id),
                    name: field.dataIndex,
                    value: e.target.value,
                  }); }}})]:[_c('a-select',{attrs:{"defaultValue":_vm.checkFields(field.dataIndex)},on:{"change":function (e) { return _vm.setField({
                    index: Number(_vm.$route.params.id),
                    name: field.dataIndex,
                    value: e,
                  }); }}},[_c('a-select-option',{attrs:{"value":"active"}},[_vm._v("Активный")]),_c('a-select-option',{attrs:{"value":"inactive"}},[_vm._v("Не активный")])],1)]],2)],1)}),1),_c('A-divider'),_c('a-row',{attrs:{"guuter":"48"}},[_c('a-col',{key:"12",staticClass:"form-item",attrs:{"span":12}},[_c('a-form-item',{staticClass:"local-prices__checkbox local-prices__additional-item",attrs:{"wrapper-col":{}}},[_c('a-checkbox',[_vm._v("Виден для других производителей")])],1)],1),_c('a-col',{key:"13",staticClass:"form-item",attrs:{"span":12}},[_c('a-form-item',{staticClass:"local-prices__additional-item",attrs:{"wrapper-col":{},"label-col":{},"label":"Наценка для других производителей"}},[_c('a-input',{attrs:{"placeholder":"Введите Количество общих артикулов"}})],1)],1)],1)],1),_c('div',{staticClass:"priceSettingsButtonsholder"},[_c('div',{staticClass:"flex"},[_c('a-button',{staticClass:"pricesSettings-button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.updatePrices(_vm.$route.params.id)}}},[_vm._v(" Сохранить ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }